import { render, staticRenderFns } from "./command-log.vue?vue&type=template&id=5bcb777f&scoped=true&"
import script from "./command-log.vue?vue&type=script&lang=js&"
export * from "./command-log.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bcb777f",
  null
  
)

export default component.exports